<template>
    <div class="modal-story">
        <div class="modal-story-blured" :style="`background-image: url(${post})`"></div>
        <div class="modal-story-content">
            <header>
                <div class="modal-story-progress"></div>
                <div class="modal-story-user">
                    <div class="modal-story-user-left">
                        <img :src="$root.imgPerfil" alt="">
                        <span>{{$root.marca}}</span>
                        <small>Ahora</small>
                    </div>
                    <div class="modal-story-user-right" @click="$emit('update:showStory', !showStory)">
                        <img src="img/equis.png" alt="" >
                    </div>
                </div>
            </header>
            <div class="modal-story-post">
                <img :src="post" alt="">
            </div>
            <footer>
                <div class="modal-story-message">Enviar mensaje</div>
                <img src="img/EnviarMensajeIg.svg" alt="">
            </footer>
        </div>
    </div>
</template>

<script>
export default{
    name: 'ModalStory',
    props: {
        post: String,
        showStory: Boolean
    },
    methods: {
        
    }
}
</script>
