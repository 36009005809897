var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.red==='fb'),expression:"red==='fb'"}],staticClass:"postFacebook"},[_c('div',{staticClass:"postFacebook-header"},[_c('div',{staticClass:"postFacebook-header-left"},[_c('div',{staticClass:"postFacebook-header-left-imgPerfil"},[_c('img',{attrs:{"src":_vm.$root.imgPerfil,"alt":""}})]),_c('div',{staticClass:"postFacebook-header-left-info"},[_c('div',{staticClass:"postFacebook-header-left-info-titulo"},[_vm._v(" "+_vm._s(_vm.$root.marca)+" ")]),_vm._m(0)])])]),_c('div',{staticClass:"postFacebook-copy"},[_c('v-clamp',{attrs:{"max-lines":2,"autoresize":""},scopedSlots:_vm._u([{key:"after",fn:function(ref){
var toggle = ref.toggle;
var expanded = ref.expanded;
var clamped = ref.clamped;
return [(expanded || clamped)?_c('small',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return toggle($event)}}},[_vm._v(_vm._s(expanded ? " Ver menos" : " Ver más"))]):_vm._e()]}}])},[_vm._v(" "+_vm._s(this.$root.posts[this.$root.postActual].copy)+" ")])],1),_c('div',{staticClass:"postFacebook-imagen"},[_c('img',{attrs:{"src":this.$root.posts[this.$root.postActual].imagen[0].url,"alt":""}})]),_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.red==='ig'),expression:"red==='ig'"}],staticClass:"postInstagram"},[_c('div',{staticClass:"postInstagram-header"},[_c('div',{staticClass:"postInstagram-header-left"},[_c('div',{staticClass:"postInstagram-header-left-imgPerfil"},[_c('img',{attrs:{"src":_vm.$root.imgPerfil,"alt":""}})]),_c('div',{staticClass:"postInstagram-header-left-nombre"},[_vm._v(" "+_vm._s(_vm.$root.marca)+" ")])])]),_c('div',{staticClass:"postInstagram-imagen"},[_c('img',{attrs:{"src":this.$root.posts[this.$root.postActual].imagen[0].url,"alt":""}})]),_vm._m(2),_c('div',{staticClass:"postInstagram-copy"},[_c('v-clamp',{attrs:{"max-lines":2,"autoresize":""},scopedSlots:_vm._u([{key:"after",fn:function(ref){
var toggle = ref.toggle;
var expanded = ref.expanded;
var clamped = ref.clamped;
return [(expanded || clamped)?_c('small',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return toggle($event)}}},[_vm._v(_vm._s(expanded ? " Ver menos" : " Ver más"))]):_vm._e()]}}])},[_vm._v(" "+_vm._s(_vm.$root.marca)+" "+_vm._s(this.$root.posts[this.$root.postActual].copy)+" ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"postFacebook-header-left-info-tiempo"},[_vm._v(" Just now "),_c('img',{attrs:{"src":"img/mundo.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"postFacebook-reacciones"},[_c('img',{attrs:{"src":"img/reaccionesFb.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"postInstagram-reacciones"},[_c('img',{attrs:{"src":"img/reaccionesIg.png","alt":""}})])}]

export { render, staticRenderFns }