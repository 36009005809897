<template>
  <div class="modal-post">
    <nav class="modal-post-menu">
      <ul v-if="tipo !== 'Google-ads'">
        <li class="modal-post-menu-opcion" :class="{'modal-post-menu-facebook':red==='fb'}" @click="red='fb'">
          Facebook
        </li>
        <li class="modal-post-menu-opcion" :class="{'modal-post-menu-instagram':red==='ig'}" @click="red='ig'" v-if="tipo !== 'Album'">
          Instagram
        </li>
      </ul>
      <ul v-else>
        <li class="modal-post-menu-opcion modal-post-menu-google" @click="red='google'">
          Google ads
        </li>
      </ul>
    </nav>
    <component :is="tipo" :red="red" :key="$root.postActual" />
    <div class="modal-post-close" @click="cerrar" v-if="$root.isMobile">
      <span><img src="img/equis.png" alt="" ></span>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import Post from "../formats/Post.vue";
import Historia from "../formats/Story.vue";
import Video from "../formats/Video.vue";
import Carrusel from "../formats/Carousel.vue";
import Collection from "../formats/Collection.vue";
import Album from "../formats/Album.vue";
import GoogleAds from "../formats/GoogleAds.vue";

export default {
  name: 'ModalVistaPrevia',
  props: {
    tipo: String
  },
  components: {
    Post,
    Historia,
    Video,
    Carrusel,
    Collection,
    Album,
    GoogleAds
  },
  data(){
    return {
      red: 'fb',
    }
  },
  methods:{
    cerrar:function(){
      this.$emit("cerrar", true);
    },
    activarFb:function(){
      this.facebookActivo=true;
      this.instagramActivo=false;
    },
    activarIg:function(){
      this.facebookActivo=false;
      this.instagramActivo=true;
    }
  }
}
</script>