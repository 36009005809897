<template>
  <div class="contenido" :class="{ 'contenido-hidden': contenidoHidden }">
    <header class="contenido-cabecera">
      <div class="contenido-cabecera-btnAtras" @click="$router.go(-1)">
        <img src="img/atras.png" alt="" />
      </div>
      <div class="contenido-cabecera-logo">
        <img :src="$root.urlLogo" alt="" />
      </div>
    </header>

    <nav class="contenido-menu">
      <ul>
        <li
          @click="activarDetalles"
          class="contenido-menu-opcion"
          :class="{ 'contenido-menu-opcion-activo': vistaDetalles }"
        >
          Detalles del contenido
        </li>
        <li
          @click="activarArgumentos"
          class="contenido-menu-opcion"
          :class="{ 'contenido-menu-opcion-activo': vistaArgumentos }"
        >
          ¿Por qué funciona?
        </li>
      </ul>
    </nav>
    <div class="contenido-detalles" v-show="vistaDetalles">
      <div class="contenido-imagen" @click="abrirModalVistaPrevia" v-if="$root.isMobile">
        <img :src="urlImagen[0].url" alt="" v-if="urlImagen[0].type !== 'video/mp4'">
        <video v-else preload="metadata">
          <source :src="`${urlImagen[0].url}#t=0.5`" />
        </video>
      </div>
      <div class="contenido-prev-btn" @click="abrirModalVistaPrevia" v-show="$root.isMobile">
        <img src="img/ojo.png" alt="" />
        Previsualizar
      </div>
      <div class="contenido-campania">
        <h3 class="contenido-titulo">
          <span>
            Copy
            <modal-multi-comment :comments.sync="comentarios" :isLoading="isLoading" :tipo="'copy'" @setComments="setComments" />
          </span>
        </h3>
        <p class="contenido-texto">
          <v-clamp :max-lines="2" autoresize>
            {{ copy }}
            <template #after="{ toggle, expanded, clamped }">
              <small
                href="#"
                v-if="expanded || clamped"
                @click.prevent="toggle"
                >{{ expanded ? " Ver menos" : " Ver más" }}</small>
            </template>
          </v-clamp>
        </p>
      </div>
      <div class="contenido-campania">
        <h3 class="contenido-titulo">
          <span>
            Campaña
            <modal-multi-comment :comments.sync="comentarios" :tipo="'campaña'" @setComments="setComments" :isLoading="isLoading" />
          </span>
        </h3>
        <p class="contenido-texto">
          {{ campaña }}
        </p>
      </div>
      <div class="contenido-tiempos">
        <div class="contenido-tiempos-info">
          <h3 class="contenido-titulo">
            <span>
              Fecha de lanzamiento
              <modal-multi-comment :comments.sync="comentarios" :tipo="'fecha'" @setComments="setComments" :isLoading="isLoading" />
            </span>
          </h3>
          <p class="contenido-texto">
            <img src="img/calendario.png" alt="" />
            {{ fecha | moment("ddd D MMM YYYY") }}
          </p>
        </div>
        <!-- <div class="contenido-tiempos-info">
          <h3 class="contenido-titulo">
            <span>
              Horario
              <modal-multi-comment :comments.sync="comentarios" :tipo="'hora'" @setComments="setComments" />
            </span>
          </h3>
          <p class="contenido-texto">
            <img src="img/reloj.png" alt="" /> {{fecha | moment("HH:mm")}}
          </p>
        </div> -->
      </div>
      <div class="contenido-pauta">
        <div class="contenido-pauta-info">
          <h3 class="contenido-titulo">
            <span>
              Periodo de pauta
              <modal-multi-comment :comments.sync="comentarios" :tipo="'periodo'" @setComments="setComments" :isLoading="isLoading" />
            </span>
          </h3>
          <p class="contenido-texto">
            <img src="img/calendario2.png" alt="" /> {{ periodo }}
          </p>
        </div>
        <div class="contenido-pauta-info">
          <h3 class="contenido-titulo">
            <span>
              Presupuesto
              <modal-multi-comment :comments.sync="comentarios" :tipo="'presupuesto'" @setComments="setComments" :isLoading="isLoading" />
            </span>
          </h3>
          <p class="contenido-texto">
            <!-- <img src="img/dolar.png" alt=""> -->
            S/{{ presupuesto }}
          </p>
        </div>
      </div>
      <div class="contenido-publico">
        <h3 class="contenido-titulo">
          <span>
            Público objetivo
            <modal-multi-comment :comments.sync="comentarios" :tipo="'objetivo'" @setComments="setComments" :isLoading="isLoading" />
          </span>
        </h3>
        <p class="contenido-texto">
          {{ publico }}
        </p>
      </div>
      <div class="contenido-redes">
        <h3 class="contenido-titulo">
          <span>
            Redes sociales
            <modal-multi-comment :comments.sync="comentarios" :tipo="'redes'" @setComments="setComments" :isLoading="isLoading" />
          </span>
        </h3>
        <div class="contenido-texto contenido-texto-interactivo">
          <div class="contenido-texto-redes">Facebook, Instagram</div>
          <div class="contenido-texto-btn" @click="abrirModalVistaPrevia" v-show="$root.isMobile">
            <img src="img/ojo.png" alt="" />
            Previsualizar
          </div>
        </div>
      </div>

      <div
        class="contenido-estado"
        @click="(modalEstado = true), (fondo = true), (contenidoHidden = true)"
      >
        <h3 class="contenido-titulo">Estado</h3>
        <div
          :class="{
            'contenido-estado-espera': estado == 'espera',
            'contenido-estado-cambios': estado == 'cambios',
            'contenido-estado-desaprobado': estado == 'desaprobado',
            'contenido-estado-aprobado': estado == 'aprobado',
          }"
        >
          <div class="contenido-estado-texto">
            {{ estadoV }}
          </div>
          <div class="contenido-estado-btnCambiar">
            Cambiar <img src="img/flechaAbajo.png" alt="" />
          </div>
        </div>
      </div>

      <div class="contenido-comentarios">
        <h3 class="contenido-titulo">
          <div class="contenido-titulo-texto">Comentarios generales</div>
          <div class="contenido-comentarios-opciones">
            <div class="modal-right-confirm" @click="successGenerals" v-show="pendiente.length">
                <img src="img/check.png" alt="" />
            </div>
            <div
              class="contenido-titulo-btn"
              @click="abrirModalComentario"
            >
              <img src="img/suma.png" alt="" />
              Agregar
            </div>
          </div>
        </h3>
        <div class="contenido-texto-comentario">
            <div class="modal-multi-content-comments pendiente">
                  <ul v-if="pendiente.length">
                      <li v-for="(com, index) in pendiente" :key="index">
                          <p>{{com.comment}}</p>
                          <small>{{com.date | moment("D MMMM, H:mm a")}}</small>
                          <hr>
                      </li>
                  </ul>
              </div>
              <div class="modal-multi-content-comments">
                  <ul v-if="noPendiente.length">
                      <li v-for="(com, index) in noPendiente" :key="index">
                          <p>{{com.comment}}</p>
                          <small>{{com.date | moment("D MMMM, H:mm a")}}</small>
                          <hr>
                      </li>
                  </ul>
              </div>
        </div>
      </div>
    </div>

    <div class="contenido-argumentos" v-show="vistaArgumentos">
      <h3 class="contenido-titulo">
        1. Ha pasado por una evaluación previa y el contenido es:
      </h3>
      <div class="contenido-checklist">
        <div class="contenido-checklist-check" :content="'La publicación cumple con los requisitos para el formato y la red social utilizada'" v-tippy ='{ placement : "bottom-start" }'>
          <img src="img/check.png" alt="" /> <span>Es funcional</span
          ><img src="img/interrogacion.png" alt="" />
        </div>
        <div class="contenido-checklist-check" :content="'La publicación llama la atención del público objetivo usando principios psicológicos que estimulan visualmente al expectador'" v-tippy ='{ placement : "bottom-start" }'>
          <img src="img/check.png" alt="" /> <span>Es atractivo</span
          ><img src="img/interrogacion.png" alt="" />
        </div>
        <div class="contenido-checklist-check" :content="'El contenido es familiar para el público objetivo y fácil de entender'" v-tippy ='{ placement : "bottom-start" }'>
          <img src="img/check.png" alt="" /> <span>Es comprensible</span
          ><img src="img/interrogacion.png" alt="" />
        </div>
        <div class="contenido-checklist-check" :content="'El contenido invita al usuario a realizar una acción motivándolo con recursos psicológicos y comunicando la propuesta diferenciadora'" v-tippy ='{ placement : "bottom-start" }'>
          <img src="img/check.png" alt="" /> <span>Es persuasivo</span
          ><img src="img/interrogacion.png" alt="" />
        </div>
      </div>
      <h3 class="contenido-titulo">
        2. Cuenta con los siguientes principios psicológicos:
      </h3>
      <div class="contenido-checklist" v-if="heuristicas">
        <div class="contenido-checklist-check" v-for="(heur, index) in heuristicas" :key="index" :content="heuristicasDesc[index]" v-tippy ='{ placement : "bottom-start" }'>
          <img src="img/check.png" alt="" /> 
          <span>{{heur}}</span
          ><img src="img/interrogacion.png" alt="" />
        </div>
      </div>
    </div>

    <modal-comentario
      v-show="modalComentario"
      @cerrar="ocultarModal()"
      :comments.sync="comentarios" 
      :tipo="'general'" 
      @setComments="setComments"
      ref="modalComentario"
    ></modal-comentario>

    <modal-estado
      v-show="modalEstado"
      :estado="estado"
      @cambiarEstado="cambiarEstado"
    ></modal-estado>

    <modal-vista-previa
      v-show="modalVistaPrevia"
      @cerrar="ocultarModal"
      :copy="copy"
      :urlImagen="urlImagen"
      :tipo="tipo"
    ></modal-vista-previa>

    <modal-why
      v-show="modalWhy"
      @cerrar="ocultarModal"
      :comments.sync="comentarios" 
      :tipo="'general'" 
      @setComments="setComments"
      :estado="estado"
    ></modal-why>

    <div v-show="fondo" class="fondoTransparente" @click="ocultarModal()"></div>

    <footer class="navegacion" v-show="$root.isMobile">
      <div
        :class="{ 'navegacion-btnInactivo': $root.postActual == 0 }"
        @click="cambiarContenido(-1)"
      >
        Anterior
      </div>
      <!-- <div class="navegacion-pagina">
          {{textoNavegacion}}
        </div> -->
      <div
        :class="{
          'navegacion-btnInactivo': $root.postActual == $root.posts.length - 1,
        }"
        @click="cambiarContenido(1)"
      >
        Aprobar siguiente <img src="img/flechaDerecha.png" alt="" />
      </div>
    </footer>
  </div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  components: {
    VClamp,
  },
  name: "PostDetail",
  props: {
    msg: String,
  },
  data() {
    return {
      modalEstado: false,
      modalComentario: false,
      modalVistaPrevia: false,
      modalWhy: false,
      fondo: false,
      vistaDetalles: true,
      vistaArgumentos: false,
      contenidoHidden: false,
      campaña: "",
      urlImagen: "",
      copy: "",
      estado: "",
      comentario: "",
      fecha: "",
      presupuesto: "",
      publico: "",
      periodo: "",
      comentarios: [],
      heuristicas: [],
      heuristicasDesc: [],
      tipo: "",
      isLoading: false
    };
  },
  created() {
    this.mostrarContenido();

    this.hayComentario =
      this.$root.posts[this.$root.postActual].comentario == "" ||
      this.$root.posts[this.$root.postActual].comentario == undefined
        ? false
        : true;

    this.fecha = this.$root.posts[this.$root.postActual].fecha;
    this.presupuesto = this.$root.posts[this.$root.postActual].presupuesto;
    this.publico = this.$root.posts[this.$root.postActual].publico;
    this.periodo = this.$root.posts[this.$root.postActual].periodo;
    
  },
  computed: {
    textoNavegacion: function () {
      return this.$root.postActual + 1 + "/" + this.$root.posts.length;
    },
    estadoV: function () {
      if (this.estado == "espera") return "En Espera";
      if (this.estado == "cambios") return "Realizar Cambios";
      if (this.estado == "aprobado") return "Aprobado";
      if (this.estado == "desaprobado") return "Desaprobado";

      return "En Espera";
    },
    pendiente: function(){
        let pendiente = this.comentarios[0]['general'].filter(( obj ) => obj.status == 'pendiente' )
        return pendiente.length ? pendiente[0].comments.reverse() : []
    },
    noPendiente: function(){
        let com = this.comentarios[0]['general'].filter(( obj ) => obj.status != 'pendiente' )
        if(!com.length) return []
        let allComments = []
        com.forEach((element) => {
            element.comments.forEach(comment => {
                allComments.push(comment)
            })
        });
        return allComments.reverse()
    }
  },
  methods: {
    successGenerals(){
        this.comentarios[0]['general'].forEach((obj) =>{
            if(obj.status == 'pendiente'){
              obj.comments.reverse()
              obj.status = 'realizado'
            }
        })
        this.setComments()
    },
    activarDetalles: function () {
      this.vistaDetalles = true;
      this.vistaArgumentos = false;
    },
    activarArgumentos: function () {
      this.vistaDetalles = false;
      this.vistaArgumentos = true;
    },
    ocultarModal: function (noScroll=false) {
      if(!this.$root.isMobile) noScroll=true
      this.fondo = false;
      this.modalEstado = false;
      this.modalComentario = false;
      this.modalVistaPrevia = false;
      this.modalWhy = false;
      this.contenidoHidden = false;
      if(this.$root.isMobile && !noScroll){
        setTimeout(() => {
          window.scrollTo(0, 1000)
          // console.log('ejecutando')
        }, 100);
      }
    },
    abrirModalVistaPrevia: function () {
      this.fondo = true;
      this.modalVistaPrevia = true;
      this.contenidoHidden = true;
      window.scrollTo(0, 0);
    },
    abrirModalComentario: function () {
      this.modalComentario = true;
      this.fondo = true;
      this.contenidoHidden = true;

      this.$refs.modalComentario.actualizarComentario();
    },
    abrirModalWhy: function () {
      this.modalWhy = true;
      this.fondo = true;
      this.contenidoHidden = true;

      // this.$refs.modalComentario.actualizarComentario();
    },
    cambiarContenido: function (delta) {
      this.$root.postActual += delta;
      this.mostrarContenido();
    },
    mostrarContenido: function () {
      var post = this.$root.posts[this.$root.postActual];
      this.campaña = post.campaña;
      this.urlImagen = post.imagen;
      this.copy = post.copy;
      this.estado = post.estado;
      // this.comentario = post.comentario;
      this.fecha = post.fecha;
      this.presupuesto = post.presupuesto;
      this.periodo = post.periodo;
      this.publico = post.publico;
      this.comentarios = JSON.parse(post.comentarios)
      console.log(JSON.parse(post.comentarios))
      this.heuristicas = post.heuristicas
      this.heuristicasDesc = post.heuristicasDesc
      this.tipo = post.tipo
      this.hayComentario =
        post.comentario == "" || post.comentario == undefined ? false : true;
    },
    cambiarEstado: function (e) {
      var post = this.$root.posts[this.$root.postActual];
      post.estado = e
      var id = this.$root.posts[this.$root.postActual].id;
      var ref = this;
      this.airtableRoi("Contenido").update(
        [
          {
            id: id,
            fields: {
              Estado: post.estado,
            },
          },
        ],
        function (err) {
          if (err) {
            console.error(err);
            return;
          }
          // ref.ocultarModal();
        }
      );

      if (post.estado == "cambios" || post.estado == "desaprobado") {
        this.abrirModalWhy();
        this.modalEstado = false;
      } else {
        ref.ocultarModal();
      }
    },
    cambiarComentario: function (c) {
      this.comentario = c;
      var id = this.$root.posts[this.$root.postActual].id;
      var ref = this;

      console.log(this.$root.postActual)

      this.hayComentario = c == "" ? false : true;

      this.airtableRoi("Contenido").update(
        [
          {
            id: id,
            fields: {
              Comentario: ref.comentario,
            },
          },
        ],
        function (err) {
          if (err) {
            console.error(err);
            return;
          }
          ref.ocultarModal();
        }
      );
    },
    setComments: function() {
      this.isLoading = true
      var id = this.$root.posts[this.$root.postActual].id;

      this.airtableRoi("Contenido").update(
        [
          {
            id: id,
            fields: {
              ComentariosJson: JSON.stringify(this.comentarios),
            },
          },
        ],
        function (err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
      this.isLoading = false
    }

  },
};
</script>