<template>
  <div class="modal-cambiarEstado">
    <h2 class="modal-cambiarEstado-titulo">
      Cambiar estado
    </h2>
    <div class="modal-cambiarEstado-opciones">
      <div class="modal-cambiarEstado-opciones-opcion modal-cambiarEstado-opciones-opcion-enEspera" @click="cambiarEstado('espera')"><div class="modal-cambiarEstado-opciones-opcion-texto ">Espera</div>
      <div class="modal-cambiarEstado-opciones-opcion-enEspera-circulo" :class="{'modal-cambiarEstado-opciones-opcion-enEspera-circulo-activo':estado=='espera'}" ></div>
      </div>
      <div class="modal-cambiarEstado-opciones-opcion modal-cambiarEstado-opciones-opcion-aprobado" @click="cambiarEstado('aprobado')"><div class="modal-cambiarEstado-opciones-opcion-texto ">Aprobado</div>
      <div class="modal-cambiarEstado-opciones-opcion-aprobado-circulo" :class="{'modal-cambiarEstado-opciones-opcion-aprobado-circulo-activo':estado=='aprobado'}" ></div></div>

      <div class="modal-cambiarEstado-opciones-opcion modal-cambiarEstado-opciones-opcion-enCambios" @click="cambiarEstado('cambios')"><div class="modal-cambiarEstado-opciones-opcion-texto ">Realizar cambios</div>
      <div class="modal-cambiarEstado-opciones-opcion-enCambios-circulo" :class="{'modal-cambiarEstado-opciones-opcion-enCambios-circulo-activo':estado=='cambios'}"></div>
        
      </div>
      <div class="modal-cambiarEstado-opciones-opcion modal-cambiarEstado-opciones-opcion-desaprobado" @click="cambiarEstado('desaprobado')"><div class="modal-cambiarEstado-opciones-opcion-texto">Desaprobado</div>
      <div class="modal-cambiarEstado-opciones-opcion-desaprobado-circulo" :class="{'modal-cambiarEstado-opciones-opcion-desaprobado-circulo-activo':estado=='desaprobado'}"></div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalEstado',
  props: {
    estado: String
  },
  mounted() {
      
  },
  data(){
    return {

    }
  },
  computed:{

  },
  methods:{
    cambiarEstado:function(e){
      this.$emit("cambiarEstado",e)
    }
  }
}
</script>