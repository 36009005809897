<template>
  <div class="menu-lista">
    <h1 class="menu-lista-titulo">
      {{nombre}}
    </h1>
    <nav class="menu-lista-opciones">
      <ul>
        <li v-for="(opcion,index) in opciones" :key="index" class="menu-lista-opciones-opcion" :class="{'menu-lista-opciones-opcion-activa':index==indexActual}" @click="seleccionarCategoria(index,opcion)"> {{opcion.nombre}}  <img v-show="index==indexActual" src="img/check.png" alt="" ></li>
 
      </ul>
    </nav>
  </div>
</template>


<script>
export default {
  name: 'CategoriaMenu',
  props: {
    nombre: String,
    opciones:Array
  },
  mounted() {
      
  },
  data(){
    return {
      indexActual:0
    }
  },
  methods:{
    seleccionarCategoria:function(a,b){
      this.indexActual=a;
      if(this.nombre=="Redes Sociales"){
        this.$emit("cambio","red",b)
      }else if(this.nombre=="Campañas"){
        this.$emit("cambio","campaña",b)
      }
      
    }
  }
}
</script>