import Vue from 'vue'
import Axios from 'axios'
import VueRouter from 'vue-router';
import Home from "./components/Views/Home.vue"
import Contenido from "./components/Views/Contenido.vue";
import Airtable from 'airtable'
import VueTippy, { TippyComponent } from "vue-tippy";
import "tippy.js/themes/light.css";

Vue.use(VueTippy);

Vue.component("tippy", TippyComponent);

const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
    moment
})

Vue.config.productionTip = false
Vue.use(VueRouter);

var base = new Airtable({apiKey: 'key5R4D9EjYyuBsZM'}).base('appatkMu7BuFGxlPr');
Vue.prototype.airtableRoi = base
Vue.prototype.axios = Axios;
//Vue.prototype.axios.defaults.baseURL = "https://www.database.vienebien.ludik.pe/api/public/api/";


Vue.component('ModalMenu', require('./components/Common/ModalMenu.vue').default);
Vue.component('ModalVistaPrevia', require('./components/Common/ModalVistaPrevia.vue').default);
Vue.component('ModalEstado', require('./components/Common/ModalEstado.vue').default);
Vue.component('ModalComentario', require('./components/Common/ModalComentario.vue').default);
Vue.component('ModalWhy', require('./components/Common/ModalWhy.vue').default);
Vue.component('Tarjeta', require('./components/Common/Tarjeta.vue').default);
Vue.component('CategoriaMenu', require('./components/Common/CategoriaMenu.vue').default);
Vue.component('ModalMultiComment', require('./components/Common/ModalMultiComment.vue').default);
Vue.component('ModalStory', require('./components/Common/ModalStory.vue').default);
Vue.component('PostDetail', require('./components/Common/PostDetail.vue').default);

const routes = [
	{ path: '/', component: Home,name:"Home",meta: { gtm: 'Home' }},
	{ path: '/contenido', component: Contenido,name:"Contenido",meta: { gtm: 'Contenido' }},
]

let router = new VueRouter({
	routes,
	// mode: 'history'
})

new Vue({
	router,
	el: '#app',
	data(){
		return {
			posts:[],
			campaña:"",
			red:"Todas",
			postActual:0,
			marca:"",
			urlLogo:"",
			imgPerfil:"",
			calendarUrl: "",
			isMobile: false
		}
	},
	created(){
		window.addEventListener('resize', () => {
            if(window.innerWidth <= 992) this.isMobile = true
            else this.isMobile = false
        })
	},
	mounted() {
		if(window.innerWidth <= 992) this.isMobile = true
		else this.isMobile = false
	},
	methods:{
		
	}
})

