<template>
  <div>
    <carousel v-bind="settings">
      <slide v-for="post in imagen" :key="post.id" class="container">
          <div class="content">
            <img
            class="story-content"
            :src="post.url"
            v-if="post.type !== 'video/mp4'"
            />
            <video class="story-content" v-else controls>
            <source :src="post.url" />
            </video>
          </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data(){
      return {
          imagen: [],
        settings: {
            perPage: 1,
            adjustableHeight: true,
            adjustableHeightEasing: 'linear',
            navigationEnabled: true,
            centerMode: true,
            paginationEnabled: true,
            paginationPosition: 'top',
            paginationPadding: 5,
            paginationSize: 5
        },
      }
  },
  mounted() {
    this.imagen = this.$root.posts[this.$root.postActual].imagen;
  },
};
</script>

<style scoped>
.content{
    width: 100%;
    height: 500px;
    background: #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    border: 1px rgb(230, 228, 228) solid;
}
.story-content{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border: 1px #000 solid;
    box-sizing: border-box;
}
</style>