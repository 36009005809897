<template>
  <div class="container">
    <div @click="toogleStory">
      <div :class="red === 'fb' ? 'story-button fb' : 'story-button ig'">
        <img :src="$root.imgPerfil" alt="" />
      </div>
      <span>{{ $root.marca }}</span>
    </div>
    <div class="modal-story" v-show="showStory">
      <div class="story-container">
        <img
          class="story-content"
          :src="imagen[currentStory].url"
          v-if="imagen[currentStory].type !== 'video/mp4'"
          :key="currentStory"
        />
        <video class="story-content" v-else :key="currentStory" ref="story">
          <source :src="imagen[currentStory].url" />
        </video>

        <div class="modal-story-content">
          <header>
            <div class="progress">
              <div
                class="progressbar"
                v-for="img in imagen"
                :key="img.id"
                id="progressbar"
              >
                <div
                  :class="`loader ${
                    playedStory.includes(img.id) ? 'currentLoader' : ''
                  }`"
                  :style="
                    duration ? `animation-duration: ${duration / 1000}s` : ''
                  "
                ></div>
              </div>
            </div>
            <div class="story-user">
              <div class="story-user-left">
                <img :src="$root.imgPerfil" alt="" />
                <span>{{ $root.marca }}</span>
                <small>Ahora</small>
              </div>
              <div class="story-user-right" @click="toogleStory" v-if="replay">
                <img src="img/equis.png" alt="" />
              </div>
            </div>
          </header>
          <footer>
            <div :class="`response response-${red}`">
              <div class="message">Responder...</div>
              <img
                :src="
                  red === 'fb' ? 'img/reactions.png' : 'img/EnviarMensajeIg.svg'
                "
                alt=""
              />
            </div>
          </footer>
        </div>
        <div class="replay" v-if="replay" @click="storyFunction"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Story",
  props: {
    red: String,
  },
  data() {
    return {
      showStory: false,
      imagen: [],
      currentStory: 0,
      playedStory: [],
      duration: 0,
      replay: false,
    };
  },
  mounted() {
    this.imagen = this.$root.posts[this.$root.postActual].imagen;
    this.playedStory = [this.imagen[0].id];
  },
  updated() {
    if (this.showStory && this.$refs.story) {
      let video = document.createElement("video");
      video.src = this.imagen[this.currentStory].url;
      console.log(video.src);
      video.addEventListener("loadedmetadata", () => {
        this.duration = video.duration * 1000;
      });
    } else {
      this.duration = 10000;
    }
    if (this.showStory) {
      if (this.$refs.story && !this.replay) {
        this.$refs.story.play();
      }
    }
  },
  methods: {
    toogleStory() {
      this.showStory = !this.showStory;
      if (this.showStory) {
        this.storyFunction();
        return;
      }
    },
    storyFunction() {
      if (!this.playedStory.length) {
        this.playedStory.push(this.imagen[0].id);
      }
      this.replay = false;
      setTimeout(() => {
        if (this.currentStory == this.imagen.length - 1) {
          this.currentStory = 0;
          this.playedStory = [];
          this.replay = true;
          return;
        }
        this.currentStory = this.currentStory + 1;
        this.playedStory.push(this.imagen[this.currentStory].id);
        this.storyFunction();
      }, this.duration);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.story-button {
  box-sizing: border-box;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.story-button img {
  border-radius: 50%;
  height: 100%;
  height: 100%;
  border: 2px solid #fff;
}
.fb {
  background: #3578e5;
}
.ig {
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}
.modal-story {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  overflow: hidden;
  max-height: 100vh;
  width: 100%;
  background: #000000;
  z-index: 8;
  display: flex;
}
.story-container {
  width: min-content;
  position: relative;
  margin: auto;
  overflow: hidden;
}
.modal-story-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  top: 0;
  position: absolute;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(41, 33, 29, 0) 25%,
    rgba(41, 33, 29, 0) 75%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: #fff;
}
.story-content {
  width: auto;
  height: 100vh;
  margin: auto;
  position: relative;
}
.progress {
  display: flex;
  padding: 15px 10px;
  gap: 5px;
}
.progressbar {
  width: 100%;
  height: 4px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.3);
}
.progressbar .loader {
  width: 0%;
  height: 4px;
  border-radius: 50px;
  background: #fff;
}
.currentLoader {
  animation: loader 10s linear forwards;
}
@keyframes loader {
  to {
    width: 100%;
  }
}
.story-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}
.story-user-left {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}
.story-user-left img {
  border-radius: 50%;
}
.response {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 15px 10px;
}
.message {
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 10px 15px;
}
.response-fb {
  overflow: hidden;
}
.response-fb img {
  height: 45px;
  width: auto;
}
.response-fb .message {
  background: rgba(255, 255, 255, 0.3);
}
.replay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 74px;
  height: 74px;
  border-style: solid;
  border-width: 37px;
  border-color: transparent transparent transparent #fff;
  box-sizing: border-box;
  width: 74px;
  height: 74px;
  border-width: 37px 0px 37px 74px;
}
</style>