<template>
  <div :class="`home-tarjeta ${tarjeta.id == this.$root.posts[this.$root.postActual].id && !$root.isMobile? 'active' :''}`" @click="goContenido">
    <div class="home-tarjeta-contenido">
      <h2 class="home-tarjeta-contenido-titulo">{{tarjeta.titulo}}</h2>
      <div class="home-tarjeta-contenido-fecha">
        <img src="img/calendario.png" alt="">
        {{ tarjeta.fecha | moment("ddd D MMM YYYY") }}
        </div>
      <div v-show="this.tarjeta.estado=='espera'" class="home-tarjeta-contenido-estado home-tarjeta-contenido-estado-espera">En Espera</div>
      <div v-show="this.tarjeta.estado=='aprobado'" class="home-tarjeta-contenido-estado home-tarjeta-contenido-estado-aprobado">Aprobado</div>
      <div v-show="this.tarjeta.estado=='cambios'" class="home-tarjeta-contenido-estado home-tarjeta-contenido-estado-desaprobado">En cambios</div>
      <div v-show="this.tarjeta.estado=='desaprobado'" class="home-tarjeta-contenido-estado home-tarjeta-contenido-estado-desaprobado">Desaprobado</div>

      <div class="home-tarjeta-contenido-estado home-tarjeta-contenido-estado-tipo">{{tarjeta.tipo}}</div>
    </div>
    <div class="home-tarjeta-imagen">
      <img :src="tarjeta.imagen[0].url" alt="" v-if="tarjeta.imagen[0].type !== 'video/mp4'">
      <video v-else preload="metadata">
        <source :src="`${tarjeta.imagen[0].url}#t=0.5`" />
      </video>
    </div>
  </div>
        
</template>

<script>
export default {
  name: 'Tarjeta',
  props: {
    tarjeta: Object,
  },
  methods:{
    goContenido:function(){
      this.$root.postActual = this.tarjeta.index;
      if(this.$root.isMobile)  this.$router.push({name:"Contenido"});
    }
  }
}

</script>