<template>
  <div class="home">
    <div v-show="!$root.isMobile" class="home-lateral-bar">
      <div class="btnMenu" @click="abrirMenu">
            <img src="img/menu.png" alt="" class="" >
      </div>
    </div>

    <div class="home-main">
        <header class="home-cabecera">
          <div class="home-cabecera-btnMenu" @click="abrirMenu" v-show="$root.isMobile">
            <img src="img/menu.png" alt="" class="" >
          </div>
          
          <div class="home-cabecera-logo">
            <img :src="$root.urlLogo" alt="" class="">
          </div>

          <div class="home-cabecera-btnMenu calendar">
            <a :href="$root.calendarUrl">
              <img src="img/businessCalendar.svg" alt="" class="" >
            </a>
          </div>
        </header>
        <nav class="home-menu">
          <ul>
            <li class="home-menu-opcion" :class="{'home-menu-opcion-activo':tabActivo=='todos'}" @click="cargar('todos')">Todos</li>
            <li class="home-menu-opcion" :class="{'home-menu-opcion-activo':tabActivo=='espera'}" @click="cargar('espera')">En espera</li>
            <li class="home-menu-opcion" :class="{'home-menu-opcion-activo':tabActivo=='aprobado'}" @click="cargar('aprobado')">Aprobados</li>
          </ul>
        </nav>
        <div class="home-tarjetas">
          <tarjeta v-for="post in $root.posts" v-bind:key="post.id" :tarjeta="post"></tarjeta>
        </div>
        <div v-show="modalMenu" class="fondoTransparente" @click="cerrarMenu">
        </div>
        <modal-menu v-show="modalMenu" :campañas="campañas" @cerrar="cerrarMenu" @cambiarPosts="cargar(tabActivo)"></modal-menu>
      </div>

      <div class="home-content" v-show="!$root.isMobile">
        <post-detail :key="this.$root.postActual" />
      </div>

      <div class="home-preview" v-show="!$root.isMobile">
        <modal-vista-previa :tipo="this.$root.posts[this.$root.postActual].tipo" :key="this.$root.postActual"/>
      </div>
    </div>
</template>

<script>
import ModalVistaPrevia from '../Common/ModalVistaPrevia.vue';
export default {
  components: { ModalVistaPrevia },
  name: 'Home',
  props: {
    msg: String
  },
  created() {
    this.cargarEmpresa();
    this.cargar('todos');
  },
  data(){
    return {
      modalMenu:false,
      tabActivo:"todos",
      campañas:[],
    }
  },
  activated() {
    // this.cargar('todos');
  },
  methods:{
    cargarEmpresa:function(){
      var ref = this;
      this.airtableRoi('Empresa').select({
        filterByFormula: "{codigoRoi} = '"+this.$route.query.id+"'",
        view: "Todas"
      }).eachPage(function page(records, fetchNextPage) {
        ref.$root.marca = records[0].fields.Name;
        ref.$root.calendarUrl = records[0].fields.Calendario;
        fetchNextPage();
        ref.cargarCampañas();

      }, function done(err) {
        if (err) { console.error(err); return; }
      });
    },
    cargarCampañas:function(){
      
      var ref = this;
      this.airtableRoi('Campaña').select({
          // Selecting the first 3 records in Grid view:
          filterByFormula: "{Empresa} = '"+ref.$root.marca+"'",
          view: "Todas"
      }).eachPage(function page(records, fetchNextPage) {
        records.forEach(function(record) {
          
              var campaña = {};
              campaña.id=record.id;
              campaña.nombre = record.get('Nombre');
              campaña.logo = record.get('Logo')?record.get('Logo')[0].thumbnails.large.url:"";
              campaña.imgPerfil = record.get('Imagen de perfil')?record.get('Imagen de perfil')[0].thumbnails.small.url:"";               

              ref.campañas.push(campaña);
          });

          

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();

      }, function done(err) {
          if (err) { console.error(err); return; }


          ref.$root.campaña = ref.campañas[0].nombre;
          
          ref.$root.urlLogo = ref.campañas[0].logo;
          
          ref.$root.imgPerfil = ref.campañas[0].imgPerfil;
          

          ref.cargar('todos')
      });
    },
    abrirMenu:function(){
      this.modalMenu=true;
    },
    cerrarMenu:function(){
      this.modalMenu=false;
    },
    cargar:function(estado){

      this.tabActivo = estado;

      this.$root.posts = [];
      var index=0;

      var ref = this;
      var filterByFormula="";

        if(estado=="todos") {
          filterByFormula = "AND({Empresa} = '"+this.$root.marca+"',{Campaña}='"+this.$root.campaña+"')"
        }else{
          filterByFormula = "AND({Empresa} = '"+this.$root.marca+"',{Campaña}='"+this.$root.campaña+"',{Estado}='"+estado+"')";
        }
        // console.log(filterByFormula);

      this.airtableRoi('Contenido').select({
      
          filterByFormula: filterByFormula,
          view: 'Todos'
      }).eachPage(function page(records, fetchNextPage) {
        
        records.map(record => {
              var tarjeta = {};
              tarjeta.id=record.id;
              
              tarjeta.copy = record.get('Copy')? record.get('Copy') : "";              
              tarjeta.imagen = record.get('Imagen')? record.get('Imagen'):""
              tarjeta.estado = record.get('Estado')?record.get('Estado'):"";
              tarjeta.titulo = record.get('Título del anuncio')?record.get('Título del anuncio'):"";
              tarjeta.campaña = record.get('CampañaS')?record.get('CampañaS'):"";
              tarjeta.fecha = record.get('Fecha de lanzamiento')?record.get('Fecha de lanzamiento'):"";
              tarjeta.presupuesto = record.get('Presupuesto')?record.get('Presupuesto'):"";
              tarjeta.publico = record.get('Público S')?record.get('Público S'):"";
              tarjeta.periodo = record.get('Periodo de pauta')?record.get('Periodo de pauta'):"";
              tarjeta.comentarios = record.get('ComentariosJson') ?? `[{
                "copy": [],
                "campaña": [],
                "fecha": [],
                "hora": [],
                "periodo": [],
                "presupuesto": [],
                "objetivo": [],
                "redes": [],
                "general": []
              }]`
              tarjeta.heuristicas = record.get('NombreHeuristica')? record.get('NombreHeuristica').split(',') : []
              tarjeta.heuristicasDesc = record.get('DescripcionHeuristica') ? record.get('DescripcionHeuristica') : []
              tarjeta.tipo = record.get('Tipo')
              tarjeta.callToAction = record.get('Call to action') ? record.get('Call to action') : ""

              
              tarjeta.index = index;

              if(ref.$root.red!="Todas"){

                if(record.get('Redes sociales').indexOf(ref.$root.red)>=0){
                  ref.$root.posts.push(tarjeta);
                  index++;
                }
              }else{
                ref.$root.posts.push(tarjeta);
                index++;
              }

            

          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
      }, function done(err) {
          
          if (err) { console.error(err); return; }
      });
    }
  }
}
</script>


