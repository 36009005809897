var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.red === 'fb'),expression:"red === 'fb'"}],staticClass:"postFacebook"},[_c('div',{staticClass:"postFacebook-header"},[_c('div',{staticClass:"postFacebook-header-left"},[_c('div',{staticClass:"postFacebook-header-left-imgPerfil"},[_c('img',{attrs:{"src":_vm.$root.imgPerfil,"alt":""}})]),_c('div',{staticClass:"postFacebook-header-left-info"},[_c('div',{staticClass:"postFacebook-header-left-info-titulo"},[_vm._v(" "+_vm._s(_vm.$root.marca)+" ")]),_vm._m(0)])])]),_c('div',{staticClass:"postFacebook-copy"},[_c('v-clamp',{attrs:{"max-lines":2,"autoresize":""},scopedSlots:_vm._u([{key:"after",fn:function(ref){
var toggle = ref.toggle;
var expanded = ref.expanded;
var clamped = ref.clamped;
return [(expanded || clamped)?_c('small',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return toggle($event)}}},[_vm._v(_vm._s(expanded ? " Ver menos" : " Ver más"))]):_vm._e()]}}])},[_vm._v(" "+_vm._s(this.$root.posts[this.$root.postActual].copy)+" ")])],1),_c('div',{staticClass:"postFacebook-imagen"},[_c('carousel',_vm._b({},'carousel',_vm.fbsettings,false),_vm._l((_vm.imagen),function(post){return _c('slide',{key:post.id,staticClass:"content"},[_c('div',{staticClass:"card-container"},[_c('img',{attrs:{"src":post.url}}),_c('div',{staticClass:"card-footer"},[_c('h3',[_c('v-clamp',{attrs:{"max-lines":2,"autoresize":""}},[_vm._v(" "+_vm._s(_vm.$root.posts[_vm.$root.postActual].titulo)+" ")])],1),_c('button',[_vm._v(_vm._s(_vm.$root.posts[_vm.$root.postActual].callToAction))])])])])}),1)],1),_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.red === 'ig'),expression:"red === 'ig'"}],staticClass:"postInstagram"},[_c('div',{staticClass:"postInstagram-header"},[_c('div',{staticClass:"postInstagram-header-left"},[_c('div',{staticClass:"postInstagram-header-left-imgPerfil"},[_c('img',{attrs:{"src":_vm.$root.imgPerfil,"alt":""}})]),_c('div',{staticClass:"postInstagram-header-left-nombre"},[_vm._v(" "+_vm._s(_vm.$root.marca)+" ")])])]),_c('div',{staticClass:"postInstagram-imagen"},[_c('carousel',_vm._b({},'carousel',_vm.igsettings,false),_vm._l((_vm.imagen),function(post){return _c('slide',{key:post.id,staticClass:"content-ig"},[_c('div',{staticClass:"card-container-ig"},[_c('img',{attrs:{"src":post.url}}),_c('div',{staticClass:"call-to-action"},[_vm._v(" "+_vm._s(_vm.$root.posts[_vm.$root.postActual].callToAction)+" ")])])])}),1)],1),_vm._m(2),_c('div',{staticClass:"postInstagram-copy"},[_c('v-clamp',{attrs:{"max-lines":2,"autoresize":""},scopedSlots:_vm._u([{key:"after",fn:function(ref){
var toggle = ref.toggle;
var expanded = ref.expanded;
var clamped = ref.clamped;
return [(expanded || clamped)?_c('small',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return toggle($event)}}},[_vm._v(_vm._s(expanded ? " Ver menos" : " Ver más"))]):_vm._e()]}}])},[_vm._v(" "+_vm._s(_vm.$root.marca)+" "+_vm._s(this.$root.posts[this.$root.postActual].copy)+" ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"postFacebook-header-left-info-tiempo"},[_vm._v(" Just now "),_c('img',{attrs:{"src":"img/mundo.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"postFacebook-reacciones"},[_c('img',{attrs:{"src":"img/reaccionesFb.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"postInstagram-reacciones"},[_c('img',{attrs:{"src":"img/reaccionesIg.png","alt":""}})])}]

export { render, staticRenderFns }