<template>
    <div class="modal-multi-content">
        <!-- botón de comentario -->
        <div class="modal-multi-content-btn" @click="(openModal = true)">
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.4C14 1.0287 13.8525 0.672601 13.5899 0.41005C13.3274 0.147499 12.9713 0 12.6 0H1.4C1.0287 0 0.672601 0.147499 0.41005 0.41005C0.1475 0.672601 0 1.0287 0 1.4V7.8C0 8.1713 0.1475 8.5274 0.41005 8.78995C0.672601 9.0525 1.0287 9.2 1.4 9.2H11.2L14 12V1.4Z" :fill="pendiente.length ? '#FDC934' : '#34449C' "/>
            </svg>
        </div>

        <!-- Modal -->
        <div v-show="openModal">
            <div  class="fondoTransparente" @click="cancel">
            </div>
            <div class="modal-comentario">
                <div class="modal-comentario-cabecera">
                    <div class="modal-comentario-cabecera-titulo">
                        Comentario
                    </div>
                    <div class="modal-right" v-show="pendiente.length">
                        <div class="modal-right-confirm" @click="successComments">
                            <img src="img/check.png" alt="" />
                        </div>
                        <div class="modal-right-more">
                            <img src="img/dot-menu.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="modal-multi-content-comments-container">
                    <div class="modal-multi-content-comments pendiente">
                        <ul v-if="pendiente.length">
                            <li v-for="(com, index) in pendiente" :key="index">
                                <p>{{com.comment}}</p>
                                <small>{{com.date | moment("D MMMM, H:mm a")}}</small>
                                <hr>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-multi-content-comments">
                        <ul v-if="noPendiente.length">
                            <li v-for="(com, index) in noPendiente" :key="index">
                                <p>{{com.comment}}</p>
                                <small>{{com.date | moment("D MMMM, H:mm a")}}</small>
                                <hr>
                            </li>
                        </ul>
                    </div>
                </div>
                <textarea v-model="comment" name="" id="multiCommentsText" cols="20" rows="8" class="modal-comentario-texto" placeholder="Agrega un comentario aquí (opcional)"></textarea>
                <div class="modal-multi-content-buttons">
                    <div class="btnGuardar" @click="saveComment" v-if="!isLoading">
                        Comentar
                    </div>
                    <div class="btnGuardar" v-else>
                        Guardando...
                    </div>
                    <div class="btnOmitir" @click="cancel">
                        Cancelar
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalMultiComment',
    props: {
        comments: Array,
        tipo: String,
        isLoading: Boolean
    },
    data() {
        return{
            openModal: false,
            comment: '',
        }
    },
    computed: {
        pendiente: function(){
            let pendiente = this.comments[0][this.tipo].filter(( obj ) => obj.status == 'pendiente' )
            return pendiente.length ? pendiente[0].comments.reverse() : []
        },
        noPendiente: function(){
            let com = this.comments[0][this.tipo].filter(( obj ) => obj.status != 'pendiente' )
            if(!com.length) return []
            let allComments = []
            com.forEach((element) => {
                element.comments.forEach(comment => {
                    allComments.push(comment)
                })
            });
            return allComments.reverse()
        }
    },
    updated() {
        if(this.openModal){
            window.scrollTo(0,0)
        }
    },
    methods: {
        saveComment(){
            if(this.comment.length){
                if(!this.pendiente.length){
                    this.comments[0][this.tipo].push({
                        status: 'pendiente',
                        comments: [
                            {comment: this.comment, date: new Date()},
                        ]
                    })
                    this.$emit('setComments')
                    this.comment=''
                    return;
                }
                this.comments[0][this.tipo].forEach((obj) =>{
                    if(obj.status == 'pendiente'){
                        obj.comments.push({comment: this.comment, date: new Date()})
                    }
                })
                this.$emit('setComments')
                this.comment=''
            }
        },
        successComments(){
            this.comments[0][this.tipo].forEach((obj) =>{
                if(obj.status == 'pendiente'){
                    obj.comments.reverse()
                    obj.status = 'realizado'
                }
            })
            this.$emit('setComments')
        },
        cancel(){
            this.openModal = false
            this.comment=''
        }
    }
}
</script>
