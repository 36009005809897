<template>
    <div>
        <div class="modal-estado" v-if="estado == 'cambios'">Realizar cambios</div>
        <div class="modal-estado" v-else>Desaprobado</div>
        <div class="modal-comentario">
            <div class="modal-comentario-cabecera">
            <div class="modal-comentario-cabecera-titulo">
                ¿Por qué no fue aprobado?
            </div>
            <img src="img/equis.png" alt="" @click="cerrar">
            </div>
            <textarea v-model="comment" name="" id="multiCommentsText" cols="20" rows="8" class="modal-comentario-texto" placeholder="Agrega un comentario aquí (opcional)"></textarea>

            <div class="btnOmitir" @click="cerrar">
            Prefiero omitir
            </div>
            <div class="btnGuardar" @click="saveComment">
            Guardar comentario
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ModalWhy',
  props: {
        comments: Array,
        tipo: String
    },
    data() {
        return{
            openModal: false,
            comment: '',
        }
    },
    computed: {
        computedComments: function(){
            let pendiente = this.comments[0][this.tipo].filter(( obj ) => obj.status == 'pendiente' )
            return pendiente.length ? pendiente[0].comments : []
        }
    },
    updated() {
        if(this.openModal){
            window.scrollTo(0,0)
        }
    },
  methods:{
    actualizarComentario:function(){
      this.comentarioEditado=this.comentario;
    },
    cerrar:function(){
      this.$emit("cerrar")
      this.comment = ''
    },
    saveComment(){
        if(this.comment.length){
            if(!this.computedComments.length){
                this.comments[0][this.tipo].push({
                    status: 'pendiente',
                    comments: [
                        {comment: this.comment, date: new Date()},
                    ]
                })
                this.$emit('setComments')
                this.comment = ''
                return;
            }
            this.comments[0][this.tipo].forEach((obj) =>{
                if(obj.status == 'pendiente'){
                    obj.comments.push({comment: this.comment, date: new Date()})
                }
            })
            this.$emit('setComments')
            this.comment = ''
        }
    },
  }
}
</script>