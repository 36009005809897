<template>
  <div>
    <div class="postFacebook" v-show="red === 'fb'">
      <div class="postFacebook-header">
        <div class="postFacebook-header-left">
          <div class="postFacebook-header-left-imgPerfil">
            <img :src="$root.imgPerfil" alt="" />
          </div>
          <div class="postFacebook-header-left-info">
            <div class="postFacebook-header-left-info-titulo">
              {{ $root.marca }}
            </div>
            <div class="postFacebook-header-left-info-tiempo">
              Just now <img src="img/mundo.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="postFacebook-copy">
        <v-clamp :max-lines="2" autoresize>
          {{ this.$root.posts[this.$root.postActual].copy }}
          <template #after="{ toggle, expanded, clamped }">
            <small
              href="#"
              v-if="expanded || clamped"
              @click.prevent="toggle"
              >{{ expanded ? " Ver menos" : " Ver más" }}</small
            >
          </template>
        </v-clamp>
      </div>
      <div class="postFacebook-imagen">
        <div class="collection">
          <div class="main-image">
            <img :src="mainImage.url" v-if="mainImage.type !== 'video/mp4'" />
            <video v-else autoplay loop muted>
              <source :src="mainImage.url" />
            </video>
          </div>
          <div :class="`call-to-action ${red}`">
            {{ $root.posts[$root.postActual].callToAction }}
          </div>
          <div class="more"><img :src="resumeContent[0].url" alt="" /></div>
          <div class="more"><img :src="resumeContent[1].url" alt="" /></div>
          <div class="more"><img :src="resumeContent[2].url" alt="" /></div>
        </div>
      </div>
      <div class="postFacebook-reacciones">
        <img src="img/reaccionesFb.png" alt="" />
      </div>
    </div>

    <div class="postInstagram" v-show="red === 'ig'">
      <div class="postInstagram-header">
        <div class="postInstagram-header-left">
          <div class="postInstagram-header-left-imgPerfil">
            <img :src="$root.imgPerfil" alt="" />
          </div>
          <div class="postInstagram-header-left-nombre">
            {{ $root.marca }}
          </div>
        </div>
      </div>
      <div class="postInstagram-imagen">
        <div class="collection">
          <div class="main-image">
            <img :src="mainImage.url" v-if="mainImage.type !== 'video/mp4'" />
            <video v-else autoplay loop muted>
              <source :src="mainImage.url" />
            </video>
          </div>
          <div class="more"><img :src="resumeContent[0].url" alt="" /></div>
          <div class="more"><img :src="resumeContent[1].url" alt="" /></div>
          <div class="more"><img :src="resumeContent[2].url" alt="" /></div>
          <div :class="`call-to-action ${red}`">
            {{ $root.posts[$root.postActual].callToAction }}
            <hr>
          </div>
        </div>
      </div>
      <div class="postInstagram-reacciones">
        <img src="img/reaccionesIg.png" alt="" />
      </div>

      <div class="postInstagram-copy">
        <v-clamp :max-lines="2" autoresize>
          {{ $root.marca }}
          {{ this.$root.posts[this.$root.postActual].copy }}
          <template #after="{ toggle, expanded, clamped }">
            <small
              href="#"
              v-if="expanded || clamped"
              @click.prevent="toggle"
              >{{ expanded ? " Ver menos" : " Ver más" }}</small
            >
          </template>
        </v-clamp>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  props: {
    red: String,
  },
  data() {
    return {
      imagen: [],
    };
  },
  mounted() {
    this.imagen = this.$root.posts[this.$root.postActual].imagen;
  },
  computed: {
    mainImage() {
      const main = this.imagen.filter(
        (archive) => archive.filename.split(".")[0] == "main"
      );
      return main[0];
    },
    resumeContent() {
      const resume = this.imagen.filter(
        (archive) => archive.filename.split(".")[0] !== "main"
      );
      return resume;
    },
  },
  components: {
    VClamp,
  },
};
</script>

<style scoped>
.collection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.main-image {
  grid-column: span 3;
}
.main-image img,
.main-image video {
  width: 100%;
  height: auto;
}
.more {
  max-width: 100%;
}
.more img {
  width: 100%;
  height: auto;
}
.call-to-action {
  grid-column: span 3;
  background: #f1f2f7;
  color: #020208;
  width: 100%;
  padding: 10px;
  text-transform: capitalize;
  box-sizing: border-box;
}
.call-to-action.ig {
  background: #fff;
  color: #389bf3;
}
.call-to-action.ig hr{
    height: 1px;
    background: #ccc;
    border: 0;
}
</style>