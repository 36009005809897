<template>
  <div class="postFacebook">
    <div class="postFacebook-header">
      <div class="postFacebook-header-left">
        <div class="postFacebook-header-left-imgPerfil">
          <img :src="$root.imgPerfil" alt="" />
        </div>
        <div class="postFacebook-header-left-info">
          <div class="postFacebook-header-left-info-titulo">
            {{ $root.marca }}
          </div>
          <div class="postFacebook-header-left-info-tiempo">
            Just now <img src="img/mundo.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="postFacebook-copy">
      <v-clamp :max-lines="2" autoresize>
        {{ this.$root.posts[this.$root.postActual].copy }}
        <template #after="{ toggle, expanded, clamped }">
          <small href="#" v-if="expanded || clamped" @click.prevent="toggle">{{
            expanded ? " Ver menos" : " Ver más"
          }}</small>
        </template>
      </v-clamp>
    </div>
    <div class="postFacebook-imagen">
        <div :class="gridType">
          <div class="main-image" >
              <img :src="mainImage.url" v-if="mainImage.type !== 'video/mp4'" />
              <video v-else controls>
                  <source :src="mainImage.url" />
              </video>
          </div>
          <div class="more" v-if="resumeContent[0]"><img :src="resumeContent[0].url" alt="" /></div>
          <div class="more" v-if="resumeContent[1]"><img :src="resumeContent[1].url" alt="" /></div>
          <div class="more" v-if="resumeContent[2]"><img :src="resumeContent[2].url" alt="" /></div>
        </div>
    </div>
    <div class="postFacebook-reacciones">
      <img src="img/reaccionesFb.png" alt="" />
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
export default {
  name: "Album",
  components: {
    VClamp,
  },
  data() {
    return {
      imagen: [],
      gridType: ''
    };
  },
  created() {
    this.imagen = this.$root.posts[this.$root.postActual].imagen;
    this.gridTypeMethod()
  },
  computed: {
    mainImage() {
      const main = this.imagen.filter(
        (archive) => archive.filename.split(".")[0] == "main"
      );
      return main[0];
    },
    resumeContent() {
      const resume = this.imagen.filter(
        (archive) => archive.filename.split(".")[0] !== "main"
      );
      return resume;
    },
  },
  methods: {
      gridTypeMethod(){
        let equal = false
        const mainSize = this.mainImage.thumbnails.large.width + this.mainImage.thumbnails.large.height
        this.resumeContent.map(imagen => {
            let imgSize= imagen.thumbnails.large.width + imagen.thumbnails.large.height
            if(imgSize == mainSize) {
                equal = true
            }
            else{
                equal = false
            }
        })
        if(equal) return this.gridType = 'normal'
        if(this.mainImage.thumbnails.large.width > 
        this.mainImage.thumbnails.large.height){
            return this.gridType = 'horizontal'
        }
        return this.gridType = 'vertical'
    }
  }
};
</script>

<style scoped>
.normal{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px
}
.horizontal{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px
}
.horizontal .main-image{
  grid-column: span 3
}
.vertical{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px
}
.vertical .main-image{
  grid-row: span 3;
  grid-column: span 2;
}
.main-image img,
.main-image video {
  width: 100%;
  height: auto;
}
.more img {
  width: 100%;
  height: auto;
}
</style>