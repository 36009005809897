<template>
  <div style="width: 100%">
    <div class="postFacebook" v-show="red === 'fb'">
      <div class="postFacebook-header">
        <div class="postFacebook-header-left">
          <div class="postFacebook-header-left-imgPerfil">
            <img :src="$root.imgPerfil" alt="" />
          </div>
          <div class="postFacebook-header-left-info">
            <div class="postFacebook-header-left-info-titulo">
              {{ $root.marca }}
            </div>
            <div class="postFacebook-header-left-info-tiempo">
              Just now <img src="img/mundo.png" alt="" />
            </div>
          </div>
        </div>

      </div>
      <div class="postFacebook-copy">
        <v-clamp :max-lines="2" autoresize>
          {{ this.$root.posts[this.$root.postActual].copy }}
          <template #after="{ toggle, expanded, clamped }">
            <small
              href="#"
              v-if="expanded || clamped"
              @click.prevent="toggle"
              >{{ expanded ? " Ver menos" : " Ver más" }}</small
            >
          </template>
        </v-clamp>
      </div>
      <div class="postFacebook-imagen">
        <carousel v-bind="fbsettings">
          <slide v-for="post in imagen" :key="post.id" class="content">
            <div class="card-container">
              <img :src="post.url" />
              <div class="card-footer">
                <h3>
                  <v-clamp :max-lines="2" autoresize>
                    {{ $root.posts[$root.postActual].titulo }}
                  </v-clamp>
                </h3>
                <button>{{ $root.posts[$root.postActual].callToAction }}</button>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
      <div class="postFacebook-reacciones">
        <img src="img/reaccionesFb.png" alt="" />
      </div>
    </div>

    <div class="postInstagram" v-show="red === 'ig'">
      <div class="postInstagram-header">
        <div class="postInstagram-header-left">
          <div class="postInstagram-header-left-imgPerfil">
            <img :src="$root.imgPerfil" alt="" />
          </div>
          <div class="postInstagram-header-left-nombre">
            {{ $root.marca }}
          </div>
        </div>

      </div>
      <div class="postInstagram-imagen">
        <carousel v-bind="igsettings">
          <slide v-for="post in imagen" :key="post.id" class="content-ig">
            <div class="card-container-ig">
              <img :src="post.url" />
              <div class="call-to-action">
                {{ $root.posts[$root.postActual].callToAction }}
              </div>
            </div>
          </slide>
        </carousel>
      </div>
      <div class="postInstagram-reacciones">
        <img src="img/reaccionesIg.png" alt="" />
      </div>

      <div class="postInstagram-copy">
        <v-clamp :max-lines="2" autoresize>
          {{ $root.marca }}
          {{ this.$root.posts[this.$root.postActual].copy }}
          <template #after="{ toggle, expanded, clamped }">
            <small
              href="#"
              v-if="expanded || clamped"
              @click.prevent="toggle"
              >{{ expanded ? " Ver menos" : " Ver más" }}</small
            >
          </template>
        </v-clamp>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "Carrusel",
  components: {
    VClamp,
    Carousel,
    Slide,
  },
  props: {
    red: String,
  },
  mounted() {
    this.imagen = this.$root.posts[this.$root.postActual].imagen;
  },
  data() {
    return {
      imagen: [],
      fbsettings: {
        perPage: 1,
        navigationEnabled: false,
        paginationEnabled: false,
        spacePadding: 20,
        touchDrag: true,
        centerMode: true,
      },
      igsettings: {
        perPage: 1,
        navigationEnabled: false,
        paginationEnabled: false,
        touchDrag: true,
        centerMode: true,
      },
    };
  },
};
</script>

<style scoped>
.content {
  padding: 0px 5px;
  box-sizing: border-box;
}
.card-container{
  border: 2px solid #f3f1f3;
  overflow: hidden;
  border-radius: 8px
}
.card-footer{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding:8px;
  box-sizing: border-box;
}
.card-footer h3{
  white-space: line-wrap;
  font-size: 1em;
}
.card-footer button{
  white-space: nowrap;
  border-radius: 3px;
  background: #fff;
  color: #5b595b;
  border: 1px solid #5b595b;
  padding: 8px;
  text-transform: uppercase;
  font-size: 0.8em;
}
.call-to-action{
  width: 100%;
  padding: 10px;
  background: rgb(123, 93, 194);
  box-sizing: border-box;
  color: white;
  text-transform: capitalize;
}
</style>