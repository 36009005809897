<template>
  <div class="menu">
      <div class="menu-btnCerrar" @click="cerrar">
        <img src="img/equis.png" alt="">
      </div>
      <categoria-menu :nombre="'Redes Sociales'" :opciones="redes" @cambio="cambio"></categoria-menu>
      <categoria-menu :nombre="'Campañas'" :opciones="campañas" @cambio="cambio"></categoria-menu>
    </div>
</template>

<script>
export default {
  name: 'ModalMenu',
  props: {
    msg: String,
    campañas:Array
  },
  mounted() {

      this.$root.red = this.redes[0].nombre;
  },
  data(){
    return {
      redes:[{nombre:'Todas'},{nombre:'Facebook'},{nombre:'Instagram'}],
      
    }
  },
  methods:{
    cerrar:function(){
      this.$emit("cerrar");
    },
    cambio:function(cat,opc){
      if(cat=="red"){
        this.$root.red = opc.nombre;
        this.$emit("cambiarPosts")
      }else if(cat=="campaña"){
        this.$root.campaña = opc.nombre;
        this.$emit("cambiarPosts")
      }
    }
  }
}
</script>