<template>
  <div>
    <div class="postFacebook" v-show="red==='fb'">
      <div class="postFacebook-header">
        <div class="postFacebook-header-left">
          <div class="postFacebook-header-left-imgPerfil">
            <img :src="$root.imgPerfil" alt="" />
          </div>
          <div class="postFacebook-header-left-info">
            <div class="postFacebook-header-left-info-titulo">
              {{ $root.marca }}
            </div>
            <div class="postFacebook-header-left-info-tiempo">
              Just now <img src="img/mundo.png" alt="" />
            </div>
          </div>
        </div>
        <!-- <div class="postFacebook-header-right" v-show="$root.isMobile">
          <img src="img/equis.png" alt="" @click="cerrar" />
        </div> -->
      </div>
      <div class="postFacebook-copy">
        <v-clamp :max-lines="2" autoresize>
          {{ this.$root.posts[this.$root.postActual].copy }}
          <template #after="{ toggle, expanded, clamped }">
            <small
              href="#"
              v-if="expanded || clamped"
              @click.prevent="toggle"
              >{{ expanded ? " Ver menos" : " Ver más" }}</small>
          </template>
        </v-clamp>
      </div>
      <div class="postFacebook-imagen">
        <video class="post-video" controls>
            <source :src="this.$root.posts[this.$root.postActual].imagen[0].url" />
        </video>
      </div>
      <div class="postFacebook-reacciones">
        <img src="img/reaccionesFb.png" alt="" />
      </div>
    </div>

    <div class="postInstagram" v-show="red==='ig'">
      <div class="postInstagram-header">
        <div class="postInstagram-header-left">
          <div class="postInstagram-header-left-imgPerfil" >
            <img :src="$root.imgPerfil" alt="">
          </div>
          <div class="postInstagram-header-left-nombre">
            {{$root.marca}}
          </div>
        </div>
        <!-- <div class="postInstagram-header-right" @click="cerrar" v-show="$root.isMobile">
          <img src="img/equis.png" alt="">
        </div> -->
        
      </div>
      <div class="postInstagram-imagen">
        <video class="post-video" controls>
            <source :src="this.$root.posts[this.$root.postActual].imagen[0].url" />
        </video>
      </div>
      <div class="postInstagram-reacciones">
        <img src="img/reaccionesIg.png" alt="">
      </div>
      
      <div class="postInstagram-copy">
        <span class="postInstagram-copy-marca">Rubik Latam</span>
        <v-clamp :max-lines="2" autoresize>
          {{ this.$root.posts[this.$root.postActual].copy }}
          <template #after="{ toggle, expanded, clamped }">
            <small
              href="#"
              v-if="expanded || clamped"
              @click.prevent="toggle"
              >{{ expanded ? " Ver menos" : " Ver más" }}</small>
          </template>
        </v-clamp>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
export default{
    name:'Video',
    components: {
      VClamp,
    },
    props: {
        red: String,
    }
}
</script>
